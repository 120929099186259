@import "./../../common/styles/variables.scss";

.ExplorePortfolioSection {
  background-color: #b645fe;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ExplorePortfolioSection-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 0 16px;

  @media screen and (min-width: $media-desktop-width) {
    max-width: $media-desktop-width;
    padding: 0;
    flex-direction: row;
  }
}

.ExplorePortfolioSection-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 73px;

  @media screen and (min-width: $media-desktop-width) {
    margin-top: 161px;
  }
}

.ExplorePortfolioSection-icon {
  height: 64px;
  width: 64px;

  @media screen and (min-width: $media-desktop-width) {
    height: 77px;
    width: 77px;
  }
}

.ExplorePortfolioSection-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #ffffff;
  margin: 5px 0 18px;
  text-align: left;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 383px;
    font-size: 45px;
    line-height: 55px;
    margin: 22px 0 30px;
  }
}

.ExplorePortfolioSection-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 152%;
  color: #fff;
  text-align: left;
  margin: 0;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 520px;
    font-size: 22px;
    line-height: 33px;
  }
}

.ExplorePortfolioSection-exploreLink {
  text-decoration: none;
  padding: 10px 0;
  border-radius: 100px;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
  margin-right: 25px;
  color: #b645fe;
  background-color: #ffffff;
  margin-top: 50px;
  margin-bottom: 47px;
  width: 100%;
  text-align: center;

  @media screen and (min-width: $media-desktop-width) {
    width: 224px;
    font-size: 18px;
    padding: 22px 34px;
    width: auto;
    margin-bottom: 162px;
  }
}

.ExplorePortfolioSection-contentIcon {
  align-self: end;
}

.ExplorePortfolioSection-contentIconMobile {
  margin-top: 52px;
  align-self: center;
}
