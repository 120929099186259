@import "./../../common/styles/variables.scss";

.ContactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #161f39;
  width: 100%;
}

.ContactSection-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 0 16px;

  @media screen and (min-width: $media-desktop-width) {
    max-width: $media-desktop-width;
    flex-direction: row;
    padding: 0;
  }
}

.ContactSection-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 64px;

  @media screen and (min-width: $media-desktop-width) {
    margin-top: 157px;
  }
}

.ContactSection-contentIcon {
  width: 64px;
  height: 64px;

  @media screen and (min-width: $media-desktop-width) {
    width: 84px;
    height: 84px;
  }
}

.ContactSection-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 34px;
  color: #ffffff;
  margin: 20px 0 14px;
  text-align: left;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 337px;
    margin: 40px 0 24px;
    font-size: 45px;
    line-height: 55px;
  }
}

.ContactSection-description {
  font-size: 18px;
  line-height: 152%;
  color: #ffffff;
  text-align: left;
  margin: 0;
  max-width: 305px;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 431px;
    font-size: 22px;
    line-height: 168%;
  }
}

.ContactSection-formContainer {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin: 35px 0 64px;

  @media screen and (min-width: $media-desktop-width) {
    padding: 45px 60px;
    margin: 76px 0 100px;
    background-color: #1f2946;
  }
}

.ContactSection-formState {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;

  &--success {
    color: green;
  }

  &--fail {
    color: red;
  }

  @media screen and (min-width: $media-desktop-width) {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.ContactSection-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ContactSection-formLabel {
  font-weight: 700;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 6px;

  &:first-child {
    margin-top: 0;
  }

  @media screen and (min-width: $media-desktop-width) {
    font-size: 18px;
    margin-top: 20px;
  }
}

.ContactSection-formInput {
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  padding: 10px 22px;
  border: 1px solid #dcdcdc;
  border-radius: 7px;
  color: #555555;
  font-size: 16px;
  line-height: 160%;
  resize: none;
  margin: 8px 0;
  font-family: $font-family-main;

  &::placeholder {
    color: rgb(85 85 85 / 80%);
    font-family: $font-family-main;
  }

  @media screen and (min-width: $media-desktop-width) {
    padding: 14px 32px;
    width: 480px;
  }
}

.ContactSection-formError {
  font-size: 16px;
  color: red;
}

.ContactSection-formSubmit {
  margin-top: 20px;
  color: #ffffff;
  background-color: #b645fe;
  border-radius: 100px;
  padding: 15px 40px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  width: 100%;

  @media screen and (min-width: $media-desktop-width) {
    width: auto;
  }
}
