@import "./../../common/styles/variables.scss";

.Main {
  background: #0E0037;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 100px;

  @media screen and (min-width: $media-desktop-width) {
    padding-bottom: 189px;
  }
}

.Main-title {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  margin: 152px 0 14px;
  text-align: center;
  max-width: calc(100vw - 32px);

  @media screen and (min-width: $media-desktop-width) {
    margin: 274px 0 24px;
    font-size: 57px;
  }
}

.Main-titleHighlight {
  color: #FEB605;
}

.Main-description {
  color: rgba(255, 255, 255, 0.9);
  margin-top: 0;
  margin-bottom: 66px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  max-width: calc(100vw - 32px);

  @media screen and (min-width: $media-desktop-width) {
    max-width: 930px;
    font-size: 22px;
    margin-bottom: 78px;
  }
}

.Main-rating {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  margin-top: 61px;
  margin-bottom: 189px;

  span {
    opacity: 0.8;
  }

  svg {
    margin-left: 8px;
    margin-right: 20px;
  }
}