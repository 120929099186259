@import "./../../common/styles/variables.scss";

.ContactButton {
  display: flex;
  align-items: center;
  padding: 6px;
  background-color: #b645fe;
  border-radius: 24px;
  color: #fff;
  text-decoration: none;
  font-family: "Noto Sans";
  font-size: 14px;
  line-height: 18px;

  @media screen and (min-width: $media-desktop-width) {
    font-size: 16px;
    line-height: 22px;
    padding: 8px 6px;
  }

  svg {
    margin-right: 10px;
    width: 26px;
    height: 26px;

    @media screen and (min-width: $media-desktop-width) {
      width: 38px;
      height: 38px;
    }
  }

  span {
    margin-right: 14px;
  }
}
