@import "./../../common/styles/variables.scss";

.ValuesSection {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  width: 100%;
  align-items: center;

  @media screen and (min-width: $media-desktop-width) {
    padding-top: 95px;
  }
}

.ValuesSection-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
  border-radius: 15px 15px 0 0;
  padding: 0 16px;
  box-sizing: border-box;

  @media screen and (min-width: $media-desktop-width) {
    max-width: $media-desktop-width;
    padding: 0 65px;
  }
}

.ValuesSection-mark {
  margin-top: 64px;
  margin-bottom: 0;
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 14px;
  color: #d0d0d0;

  span {
    color: #b645fe;
  }

  @media screen and (min-width: $media-desktop-width) {
    margin-top: 71px;
    font-size: 15px;
  }
}

.ValuesSection-title {
  font-weight: 700;
  font-size: 26px;
  margin: 10px 0 18px;
  text-align: center;
  max-width: 280px;

  @media screen and (min-width: $media-desktop-width) {
    font-size: 40px;
    max-width: 100%;
  }
}

.ValuesSection-valuesList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 60px;
  margin-top: 50px;
  margin-bottom: 64px;

  @media screen and (min-width: $media-desktop-width) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 62px;
    row-gap: 83px;
    margin-top: 83px;
    margin-bottom: 124px;
  }
}

.ValuesSection-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ValuesSection-valueIcon {
  display: flex;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  &--purple {
    background-color: #d697ff;
  }
  &--yellow {
    background-color: #FEB605;
  }
  &--green {
    background-color: #D9EE55;
  }
  &--pink {
    background-color: #FBC9FE;
  }
  &--blue {
    background-color: #A4E4E3;
  }
  &--orange {
    background-color: #FFBC97;
  }
}

.ValuesSection-valueTitle {
  margin: 16px 0 10px;
  color: #454545;
  font-weight: 700;
  font-size: 22px;

  @media screen and (min-width: $media-desktop-width) {
    margin: 36px 0 10px;
  }
}

.ValuesSection-valueDescription {
  font-weight: 400;
  font-size: 16px;
  color: #454545;
  text-align: left;
  line-height: 161.7%;
  margin: 0;
}
