@import "./../../common/styles/variables.scss";

.Portfolio-main {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 152px 16px 80px;
  background-image: url("./../../common/assets/portfolio-banner.png");
  background-size: cover;
  background-position: center;

  @media screen and (min-width: $media-desktop-width) {
    padding: 274px 16px 80px;
  }
}

.Portfolio-mainTitle {
  margin: 0 0 14px;
  color: #ffffff;
  font-weight: 700;
  font-size: 32px;
  line-height: 131.9%;
  text-align: center;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 700px;
    font-size: 57px;
    line-height: 73px;
    margin: 0 0 24px;
  }
}

.Portfolio-mainDescription {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 166.2%;
  color: #ffffff;
  margin: 0;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 930px;
    font-size: 22px;
  }
}

.Portfolio-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Portfolio-galleryContainer {
  box-sizing: border-box;
  width: 100%;
  padding: 60px 16px;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 40px;
  row-gap: 83px;

  @media screen and (min-width: $media-tablet-width) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: $media-desktop-width) {
    padding: 80px 0;
    max-width: $media-desktop-width;
    grid-template-columns: repeat(3, 1fr);
  }
}

.Portfolio-case {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Portfolio-caseContent {
  display: flex;
  flex-direction: column;
}

.Portfolio-caseFoto {
  border-radius: 16px;
  margin-bottom: 22px;
  width: 100%;

  @media screen and (min-width: $media-desktop-width) {
    width: 304px;
    height: 304px;
  }
}

.Portfolio-caseTitle {
  margin: 0 0 16px;
  color: #2f2f2f;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
}

.Portfolio-caseDescription {
  color: #626262;
  font-weight: 400;
  font-size: 16px;
  line-height: 151.5%;
  margin: 0;
}

.Portfolio-caseDetailsBtn {
  color: #2f2f2f;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-top: 8px;

  @media screen and (min-width: $media-desktop-width) {
    width: fit-content;
  }
}

.Modal-background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: hidden;
  overscroll-behavior: contain;
}

.Modal {
  position: fixed;
  z-index: 1;
  background-color: #fff;
  display: flex;
  width: calc(100vw - 64px);
  max-width: $media-desktop-width;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #626262;
  max-height: calc(100vh - 64px);
  padding-bottom: 16px;
}

.Modal-header {
  display: flex;
  padding: 24px;
  justify-content: flex-end;
}

.Modal-headerBtn {
  cursor: pointer;
  background: none;
  border: none;
  padding: 6px;
}

.Modal-headerIcon {
  width: 24px;
  height: 24px;
}

.Modal-content {
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.Modal-title {
  color: #0d1326;
  font-size: 40px;
  line-height: 47px;
  margin: 0 0 16px;
}

.Modal-description {
  color: #0d1326;
  font-size: 18px;
  line-height: 168%;

  p {
    margin: 8px 0;
  }
}

.Modal-stack {
  color: #0d1326;
  font-size: 22px;
  margin-bottom: 16px;
}

.Modal-stackItem {
  color: #0d1326;
  font-size: 18px;
  margin-bottom: 8px;

  &:last-child {
    margin: 0;
  }
}
