@import "./../../common/styles/variables.scss";

.FoundersSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 0 16px;

  @media screen and (min-width: $media-desktop-width) {
    padding: 0;
  }
}

.FoundersSection-mark {
  margin-top: 54px;
  margin-bottom: 0;
  font-family: "Noto Sans";
  font-weight: 500;
  font-size: 14px;
  color: #d0d0d0;

  span {
    color: #ffdc60;
  }

  @media screen and (min-width: $media-desktop-width) {
    font-size: 15px;
  }
}

.FoundersSection-title {
  font-weight: 700;
  font-size: 26px;
  margin: 10px 0;
  max-width: calc(100vw - 48px);
  text-align: center;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 515px;
    font-size: 40px;
  }
}

.FoundersSection-description {
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 16px;
  text-align: center;
  max-width: calc(100vw - 32px);

  @media screen and (min-width: $media-desktop-width) {
    max-width: 610px;
    font-size: 18px;
    margin: 0 0 55px;
  }
}

.FoundersSection-foundersList {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $media-desktop-width) {
    flex-direction: row;
  }
}

.FoundersSection-founder {
  padding: 36px 32px;
  border-radius: 15px;
  background: linear-gradient(151.16deg, #fff8f8 10.38%, #f5f7ff 95.44%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  @media screen and (min-width: $media-desktop-width) {
    margin-bottom: 0;
    margin-right: 58px;
  }
}

.FoundersSection-founderImage {
  border-radius: 30px 30px 0px 0px;
  width: 330px;
  height: 330px;
  object-fit: cover;
}

.FoundersSection-founderName {
  font-weight: 700;
  font-size: 20px;
  margin: 24px 0 6px;
  color: #292930;

  @media screen and (min-width: $media-desktop-width) {
    font-size: 25px;
    margin: 30px 0 8px;
  }
}

.FoundersSection-founderTitle {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  color: #555555;

  @media screen and (min-width: $media-desktop-width) {
    font-size: 18px;
  }
}

.FoundersSection-links {
  display: flex;
  flex-direction: column;
  margin-top: 82px;
  margin-bottom: 69px;
  margin-top: 34px;
  margin-bottom: 64px;

  @media screen and (min-width: $media-desktop-width) {
    flex-direction: row;
    margin-top: 82px;
    margin-bottom: 69px;
  }
}

.FoundersSection-link {
  text-decoration: none;
  padding: 22px 34px;
  border-radius: 100px;
  box-sizing: border-box;
  font-family: "Noto Sans";
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 14px;
  text-align: center;

  &--filled {
    background-color: #b645fe;
    color: #ffffff;
  }

  &--outlined {
    color: #062129;
    border: 1px solid #282828;
  }

  width: 100%;

  @media screen and (min-width: $media-desktop-width) {
    width: 224px;
    margin-right: 25px;
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
