@import "./../../common/styles/variables.scss";

.Header {
  display: flex;
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  padding: 12px 14px;
  justify-content: center;
  transition: background-color 0.5s;
  backdrop-filter: saturate(180%) blur(20px);

  &--light {
    background-color: rgb(255 255 255 / 80%);

    .Header-navigationLink {
      color: #0d1326;
    }

    svg {
      fill: #0D1326;
    }
    
    @media screen and (min-width: $media-desktop-width) {
      padding: 12px 48px;
    }
  }

  &--dark {
    background-color: rgb(17 25 48 / 80%);

    .Header-navigationLink {
      color: #fff;
    }

    svg {
      fill: #fff;
    }
  }
}

.Header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (min-width: $media-desktop-width) {
    max-width: $media-desktop-width;
  }
}

.Header-logo {
  transition: fill 0.5s;
}

.Header-navigation {
  display: flex;
}

.Header-navigationLink {
  text-decoration: none;
  font-family: "Roboto";
  margin-right: 42px;
  font-size: 14px;
  padding: 8px;
  transition: color 0.5s;

  &:last-child {
    margin-right: 0;
  }
}

.Header-actions {
  display: flex;
}

.Header-menuButton {
  padding: 12px 10px;
  background-color: #0E0434;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 37px;
  height: 37px;
}

.Header-menu {
  position: absolute;
  top: 62px;
  width: 100%;
  height: calc(100vh - 62px);
  background-color: #B645FE;
}

.Header-menuNavigation {
  display: flex;
  flex-direction: column;
  padding: 50px 16px;
}

.Header-menuNavigationLink {
  text-decoration: none;
  margin-bottom: 3px;
  padding: 8px;

  font-weight: 700;
  font-size: 38px;
  line-height: 49px;
  color: #FFFFFF;

  &:last-child {
    margin-right: 0;
  }
}
