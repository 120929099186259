@import "./../../common/styles/variables.scss";

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #0d1326;
}

.Footer-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: $media-desktop-width) {
    max-width: $media-desktop-width;
    flex-direction: row;
  }
}

.Footer-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 26px;
  align-items: center;
  border-bottom: 1px solid #334360;

  @media screen and (min-width: $media-desktop-width) {
    padding-top: 60px;
    padding-right: 100px;
    padding-bottom: 53px;
    align-items: flex-start;
    border-bottom: none;
  }
}

.Footer-contactTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #fdfefd;
  margin: 0 0 25px;
}

.Footer-contactLink {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.9;
  background: rgba(255, 255, 255, 0.3);
  padding: 12px 24px;
  border-radius: 100px;
  text-decoration: none;

  @media screen and (min-width: $media-desktop-width) {
    font-size: 18px;
    line-height: 23px;
    padding: 14px 24px;
  }
}

.Footer-sitemap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 100px 53px;
  flex-grow: 1;
  border-left: 1px solid #334360;
  border-right: 1px solid #334360;
}

.Footer-sitemapTitle {
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #fdfefd;
}

.Footer-sitemapLink {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #fdfefd;
  margin-bottom: 12px;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }
}

.Footer-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;

  @media screen and (min-width: $media-desktop-width) {
    padding-top: 60px;
    padding-left: 100px;
    padding-bottom: 53px;
    padding-right: 0;
    align-items: flex-start;
  }
}

.Footer-socialTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fdfefd;
  margin: 0 0 24px;
}

.Footer-socialLinksCnt {
  display: flex;
}

.Footer-socialLink {
  display: flex;
  margin-right: 30px;
  height: 42px;
  width: 42px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: $media-desktop-width) {
    height: 50px;
    width: 50px;
  }
}

.Footer-legal {
  display: flex;
  padding: 30px 0;
  justify-content: flex-start;

  @media screen and (min-width: $media-desktop-width) {
    justify-content: center;
    padding: 40px 0;
  }
}

.Footer-legalTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fdfefd;
  margin: 0;

  @media screen and (min-width: $media-desktop-width) {
    font-size: 16px;
    line-height: 21px;
  }
}

.Footer-border {
  border-bottom: 1px solid #334360;
  display: flex;
  width: 100%;
  height: 0;
}
