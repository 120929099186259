@import "./../../common/styles/variables.scss";

.AboutUs-main {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 152px 16px 80px;
  background-image: url("./../../common/assets/portfolio-banner.png");
  background-size: cover;
  background-position: center;

  @media screen and (min-width: $media-desktop-width) {
    padding: 274px 16px 80px;
  }
}

.AboutUs-mainTitle {
  margin: 0 0 14px;
  color: #ffffff;
  font-weight: 700;
  font-size: 32px;
  line-height: 131.9%;
  text-align: center;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 700px;
    font-size: 57px;
    line-height: 73px;
    margin: 0 0 24px;
  }
}

.AboutUs-mainDescription {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 166.2%;
  color: #ffffff;
  margin: 0;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 930px;
    font-size: 22px;
  }
}

.AboutUs-desctiption {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.AboutUs-conteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;

  @media screen and (min-width: $media-desktop-width) {
    max-width: $media-desktop-width;
    padding: 40px;
  }
}

.AboutUs-banner {
  border-radius: 16px;
  max-width: 100%;

  @media screen and (min-width: $media-desktop-width) {
    max-width: 600px;
  }
}

.AboutUs-content {
  padding: 40px 16px 0;
  max-width: 740px;

  @media screen and (min-width: $media-desktop-width) {
    padding: 40px 32px 0;
  }

  p {
    color: #626262;
    font-size: 16px;
    line-height: 150.5%;
    margin: 18px 0;
  }

  h4 {
    color: #2F2F2F;
    font-size: 20px;
    line-height: 150.5%;
    margin: 24px 0 16px;
  }

  li {
    color: #626262;
    font-size: 16px;
    line-height: 150.5%;
    margin: 10px 0;
  }
}
